<div class="container">
  <div class="card">
    <div class="inputs">
      <div class="input">
        <h2 class="input-label">Your Name</h2>
        <input #singer type="text" [formControl]="singerControl" />
      </div>

      <div class="input">
        <h2 class="input-label">YouTube Link</h2>
        <input type="text" [formControl]="songControl" />
      </div>
    </div>
    <karaoke-button class="submit" [title]="'Submit New Song'" (click)="addNewSongClicked()"></karaoke-button>
  </div>
</div>
